import { useEffect, useRef } from "react";
import "./Navbar.css";

const Navbar = () => {
	// align to the right - home, skills, Projects, contact

	// mobile hamburger menu click
	// show mobile menu
	// hide mobile menu

	const navbarMobileHamburgerRef = useRef(null);
	const navbarMobileMenuRef = useRef(null);

	useEffect(() => {
		const toggleMenu = () => {
			if (navbarMobileMenuRef.current) {
				navbarMobileMenuRef.current.classList.toggle("navbar-mobile-open");
				navbarMobileMenuRef.current.classList.toggle("navbar-mobile-close");
			}
		};

		const hideMenu = () => {
			if (navbarMobileMenuRef.current) {
				navbarMobileMenuRef.current.classList.remove("navbar-mobile-open");
				navbarMobileMenuRef.current.classList.add("navbar-mobile-close");
			}
		};

		// if click anywhere outside of the navbar mobile menu, hide the menu
		document.addEventListener("click", (e) => {
			if (
				navbarMobileMenuRef.current &&
				!navbarMobileHamburgerRef.current.contains(e.target)
			) {
				hideMenu();
			}
		});

		const hamburgerElem = navbarMobileHamburgerRef.current;
		if (hamburgerElem) {
			hamburgerElem.addEventListener("click", toggleMenu);
		}

		return () => {
			if (hamburgerElem) {
				hamburgerElem.removeEventListener("click", toggleMenu);
			}
		};
	}, []);

	return (
		<div className="navbar">
			<div className="navbar-link-container">
				<a href="#home" className="navbar-link">
					Home
				</a>
				{/* skills */}
				{/* projects */}
				{/* contact me */}
				<a href="#skills" className="navbar-link">
					Skills
				</a>
				<a href="#projects" className="navbar-link">
					Projects
				</a>
				<a href="#contact" className="navbar-link">
					Contact
				</a>
			</div>
			<div className="navbar-mobile">
				<div className="navbar-mobile-hamburger" ref={navbarMobileHamburgerRef}>
					<div className="navbar-mobile-hamburger-line" />
					<div className="navbar-mobile-hamburger-line" />
					<div className="navbar-mobile-hamburger-line" />
				</div>
			</div>
			<div
				className="navbar-mobile-menu navbar-mobile-close"
				ref={navbarMobileMenuRef}
			>
				<a href="#home" className="navbar-mobile-menu-link">
					Home
				</a>
				<a href="#skills" className="navbar-mobile-menu-link">
					Skills
				</a>
				<a href="#projects" className="navbar-mobile-menu-link">
					Projects
				</a>
				<a href="#contact" className="navbar-mobile-menu-link">
					Contact
				</a>
			</div>
		</div>
	);
};

export default Navbar;
