import "./Project.css";
import SkillsPill from "../Skills/SkillsPill";
import React, { useState, useEffect } from "react";

// every 5 seconds show the next photo in the img array
// when the last photo is shown, show the first photo again
const ProjectImg = ({ img, fadeOut }) => {
	return (
		<img
			src={img}
			alt="project"
			className={`project-img ${fadeOut ? "fade-out" : ""}`}
			loading="lazy"
		/>
	);
};

const Project = ({ name, skills, features, img }) => {
	const images = [];

	const preloadImages = (imageArray) => {
		imageArray.forEach((src) => {
			const newImage = new Image();
			newImage.src = src;

			// Add to images array
			images.push(newImage.src);
		});
	};

	preloadImages(img);

	const [currentImg, setCurrentImg] = useState(images[0]);
	const [fadeOut, setFadeOut] = useState(false);

	let i = 0;

	useEffect(() => {
		const interval = setInterval(() => {
			if (images.length === 1) return;
			setFadeOut(true);

			setTimeout(() => {
				i++;
				if (i === images.length) {
					i = 0;
				}
				setCurrentImg(images[i]);
				setFadeOut(false);
			}, 500); // Matches the CSS transition duration
		}, 5000);

		return () => clearInterval(interval); // Clear interval on unmount
	}, []);

	return (
		<div className="project">
			<div className="project-img-container">
				<ProjectImg img={currentImg} fadeOut={fadeOut} />
			</div>
			<div className="project-info">
				<div className="project-info-column">
					<h2 className="project-name">{name}</h2>
				</div>
				<div className="project-info-column">
					<h2 className="project-header">Stack</h2>
					<div className="project-skills-container">
						{skills.map((skill) => (
							<SkillsPill skill={skill} />
						))}
					</div>
				</div>
				<div className="project-info-column">
					<h2 className="project-header">Features</h2>
					<div className="project-features-container">
						{features.map((feature) => (
							<div className="project-feature">{feature}</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Project;
