import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import SectionHeader from "./Components/Headers/Headers";
import SkillsBar from "./Components/Skills/SkillsBar";
import SkillsPill from "./Components/Skills/SkillsPill";
import Project from "./Components/Projects/Project";
import Divider from "./Components/Divider/Divider";

function App() {
	document.addEventListener("DOMContentLoaded", function () {
		// Get all the anchor tags
		const anchors = document.querySelectorAll('a[href*="#"]');

		// Loop through each anchor tag and attach the smooth scroll event
		anchors.forEach((anchor) => {
			anchor.addEventListener("click", function (e) {
				// Prevent the default action
				e.preventDefault();

				// Get the target element ID from the anchor href
				const targetID = this.getAttribute("href").substring(1);
				const targetElement = document.getElementById(targetID);

				// Scroll to the target element using smooth scroll
				window.scrollTo({
					top: targetElement.offsetTop,
					behavior: "smooth",
				});
			});
		});
	});

	return (
		<div className="App">
			<Navbar />
			<div className="hero">
				<div className="hero-background" />
				<div className="shooting-star" />
				<div className="shooting-star" />
				<div className="shooting-star" />
				<div className="shooting-star" />
				<div className="shooting-star" />
				{/* <div className="space-station">
					<div className="space-station-img" />
				</div> */}
				<div className="hero-left">
					<h1 className="hero-name">DANIEL HAYNES</h1>
					<h2 className="hero-role">Full Stack Developer</h2>
					<div className="hero-socials"></div>
				</div>
			</div>
			<div id="skills" className="section">
				<SectionHeader title="Skills" />
				<div className="skills-container">
					<SkillsBar
						img={"/imgs/logos/nodejs.svg"}
						years={7}
						knowledgePercent={80}
						scope="Backend/Full Stack"
						level="Advanced"
					/>
					<SkillsBar
						img={"/imgs/logos/react.svg"}
						years={7}
						knowledgePercent={80}
						scope="Frontend"
						level="Advanced"
					/>
					<SkillsBar
						img={"/imgs/logos/lua.svg"}
						years={10}
						knowledgePercent={99}
						scope="Full Stack"
						level="Expert"
					/>
					<SkillsBar
						img={"/imgs/logos/mysql.svg"}
						years={10}
						knowledgePercent={70}
						scope="DB"
						level="Advanced"
					/>
					<SkillsBar
						img={"/imgs/logos/mongodb.svg"}
						years={7}
						knowledgePercent={80}
						scope="DB"
						level="Advanced"
					/>
					<SkillsBar
						img={"/imgs/logos/docker.svg"}
						years={5}
						knowledgePercent={60}
						scope="DevOps"
						level="Expert"
					/>
				</div>
			</div>

			<div className="section">
				<SectionHeader title="Other Skills" />
				<div className="otherskills-container">
					<SkillsPill skill="Git" />
					<SkillsPill skill="HTML" />
					<SkillsPill skill="CSS" />
					<SkillsPill skill="Docker" />
					<SkillsPill skill="Express" />
					<SkillsPill skill="Kubernetes" />
					<SkillsPill skill="SQL" />
					<SkillsPill skill="Unit Testing" />
					<SkillsPill skill="AWS" />
					<SkillsPill skill="AWS Lambda" />
					<SkillsPill skill="CI/CD" />
					<SkillsPill skill="Load Balancing" />
					<SkillsPill skill="Linux" />
					<SkillsPill skill="Nginx" />
					<SkillsPill skill="Project Management" />
				</div>
			</div>

			<div id="projects" className="section">
				<SectionHeader title="Projects" />
				<div className="projects-container">
					<Project
						name="Internal Staffing Software"
						skills={[
							"React",
							"NodeJS",
							"MongoDB",
							"MySQL",
							"Typescript",
							"Redis",
							"Kubernetes",
						]}
						features={[
							"Intergration to manage staff accounts and permissions across our different platforms",
							"Automated rules to manage onboarding and offboarding processes",
							"Auto scaling and high availability with Kubernetes",
						]}
						img={["/imgs/projects/staff.png", "/imgs/projects/roster.png"]}
					/>
					<Divider />

					<Project
						name="Game Ranking & Leaderboard"
						skills={[
							"React",
							"NodeJS",
							"MongoDB",
							"MySQL",
							"Typescript",
							"Lua",
						]}
						features={[
							"Panel to automate user's in-game ranking and permissions unique to those rankings",
							"Data aggregation such as time played to better manage players",
							"Auto scaling and high availability with Kubernetes",
						]}
						img={["/imgs/projects/roster.png"]}
					/>
					<Divider />

					<Project
						name="Standalone Store"
						skills={[
							"NodeJS",
							"Pug",
							"MongoDB",
							"MySQL",
							"Typescript",
							"Lua",
							"Kubernetes",
						]}
						features={[
							"Standalone store with Stripe, PayPal, and Coinbase payment gateways",
							"Optimized client experience by rendering site server side via Pug",
							"Auto scaling and high availability with Kubernetes to ensure uptime during sales and large holidays",
						]}
						img={[
							"/imgs/projects/store1.png",
							"/imgs/projects/store2.png",
							"/imgs/projects/store3.png",
							"/imgs/projects/store4.png",
						]}
					/>
					<Divider />

					{/* <Project
						name="Advanced Analytics"
						skills={["NodeJS", "React", "MongoDB", "Typescript", "Lua"]}
						features={[
							"Platform to view/manage analytics specific to our industry",
							"Track metrics such as player retention(1 day, 7 day, etc), LTV, and sales funnel journey.",
						]}
						img={["/imgs/logos/react.png"]}
					/>
					<Divider /> */}

					<Project
						name="Loading Screen - Garry's Mod"
						skills={["NodeJS", "React", "MongoDB", "Typescript", "Lua"]}
						features={[
							"Loading Screen/Analytics reporting to provide us with connection errors, load in times, and provide player's assistance connecting better.",
							"Metrics for error rates and load in times help us make informed discussions about optimizations that need to be prioritized.",
						]}
						img={["/imgs/projects/loading_screen.png"]}
					/>
					<Divider />

					<Project
						name="Home Security - Wall Mounted Tablet"
						skills={["Typescript", "React", "Raspberry Pi", "Electron"]}
						features={[
							"Security Camera streaming to tablet",
							"Event driven door, window, and motion sensor statuses for live updates",
						]}
						img={["/imgs/projects/security.png"]}
					/>
				</div>
			</div>

			<div id="contact" className="section">
				<SectionHeader title="Contact Me" />
				<div className="contact-container">
					<div className="contact-column">
						<a className="contact-header" href="mailto:danhaynes9@gmail.com">
							{" "}
							Email{" "}
						</a>
					</div>
					<div className="contact-column">
						<a
							className="contact-header"
							href="https://www.linkedin.com/in/daniel-haynes1/"
						>
							{" "}
							LinkedIn{" "}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
