import "./SkillsBar.css";
import React, { useEffect } from "react";

const SkillsBar = ({ img, years, knowledgePercent, scope, level }) => {
	useEffect(() => {
		const options = {
			threshold: 0.9, // 70% of the target visible
		};

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					// entry.target.classList.add("open");
					// remove closed
					entry.target.classList.remove("closed");
				}
			});
		}, options);

		const skillBars = document.querySelectorAll(".skills-bar");

		skillBars.forEach((skillBar) => {
			observer.observe(skillBar);
		});

		// Cleanup
		return () => {
			skillBars.forEach((skillBar) => {
				observer.unobserve(skillBar);
			});
		};
	}, []);

	return (
		<div className="skills-bar closed">
			<div className="skills-bar-img-container">
				<img src={img} alt="skill" className="skills-bar-img" />
			</div>

			<div className="skills-bar-info">
				<div className="skills-bar-info-column">
					<h2>YOE</h2>
					<h3>{years}+ years</h3>
				</div>
				<div className="skills-bar-info-column">
					<h2>KNOWLEDGE</h2>
					<h3>{knowledgePercent}%</h3>
				</div>
				<div className="skills-bar-info-column">
					<h2>SCOPE</h2>
					<h3>{scope}</h3>
				</div>
			</div>

			{/* level */}
			<div className="skills-bar-level">
				<h3>{level}</h3>
			</div>
		</div>
	);
};

export default SkillsBar;
