import { useEffect } from "react";
import "./SkillsPill.css";

const SkillsPill = ({ skill, url }) => {
	// if url is passed in, make the pill a link
	// else, make the pill not a link

	// useEffect(() => {
	// 	if (url) {
	// 		const skillsPills = document.querySelectorAll(".skills-pill-link");

	// 		skillsPills.forEach((skillsPill) => {
	// 			skillsPill.addEventListener("click", () => {
	// 				// open the url in a new tab
	// 				window.open(url, "_blank");
	// 			});
	// 		});

	// 		// Cleanup
	// 		return () => {
	// 			skillsPills.forEach((skillsPill) => {
	// 				skillsPill.removeEventListener("click", () => {
	// 					window.open(url, "_blank");
	// 				});
	// 			});
	// 		};
	// 	}
	// }, []);

	return url ? (
		<a
			className="skills-pill skills-pill-link"
			href={url}
			target="_blank"
			rel="noreferrer"
		>
			{skill}
		</a>
	) : (
		<div className="skills-pill">{skill}</div>
	);

	// <a
	// 	className={url ? "skills-pill skills-pill-link" : "skills-pill"}
	// 	href={url || ""}
	// >
	// 	{skill}
	// </a>
};

export default SkillsPill;
